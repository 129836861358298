const LocationReviewsData = [
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Jason Bush",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Patricia C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Jennifer Moreno",
        "service_type": "Garage Door Opener",
        "tech_name": "Tammy W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Shelley Stephenson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Samuel W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Thomas Juarez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mitchell P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Lauren Alvarez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "John H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Amy Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amanda S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Michael Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Cassandra Montgomery",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Craig M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Regina Martin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "James Ward",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexandra S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Anthony Wiggins",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kelly S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Timothy Colon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Linda Thompson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Heidi B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Stacey Roberson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Deborah C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Andrew Rodriguez MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tyler D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Jason Poole",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Rebecca Webb",
        "service_type": "Garage Door Services",
        "tech_name": "Angela M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Alejandro Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shirley C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Rachel Morris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Isaiah Watson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Courtney Richards",
        "service_type": "Garage Door Opener",
        "tech_name": "Paul S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Sean Shelton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jason B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Christian Figueroa",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Laura Salinas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven R.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Chad Hunter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Clayton S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Brittany Vaughn",
        "service_type": "Garage Door Services",
        "tech_name": "Sarah D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Alexander Jackson",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin A.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Jon Christian",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Matthew Gilbert",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jean K.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Maria Rodriguez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Timothy S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Mr. Sean Silva",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ryan P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Zachary Russell",
        "service_type": "Garage Door Repair",
        "tech_name": "Carrie L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Aaron Mathews",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angela H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Mr. Andrew Harris Jr.",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laurie L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Michael Simpson",
        "service_type": "Garage Door Installation",
        "tech_name": "Jay M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Blake Barber",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sarah M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Kelly Lara",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "Jared Stevenson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Shawn A.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Stephanie Wheeler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gregory S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Christopher Baldwin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jill M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Samantha Cruz",
        "service_type": "Garage Door Opener",
        "tech_name": "Justin D.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Michael Powell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicholas A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Christina Richards",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristina H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Willie Hall",
        "service_type": "Garage Door Installation",
        "tech_name": "Robin S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Dale Lewis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Pamela V.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Shannon Blanchard",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Patel",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert P.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Amy Erickson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Dr. Thomas Gutierrez",
        "service_type": "Garage Door Repair",
        "tech_name": "Peter S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Andrew Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Charles Parker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rose B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Jose Fuller",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John Z.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Kelly Kelley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Richard K.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Bonnie Howell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Derek B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Amanda Ryan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Carla M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Marc Webb",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Connie W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Bill Hensley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Erin Cook",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mariah J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Brian Carter",
        "service_type": "Garage Door Repair",
        "tech_name": "Rebecca T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Sally Dillon",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Jason Terry Jr.",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Denise H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "James Alvarez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nathan J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Jeremy Le",
        "service_type": "Garage Door Opener",
        "tech_name": "Autumn R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Kevin Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Phillip R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Chris Love",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jonathan M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Jessica Wilkinson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ross R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Jessica Brown",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Sara Dean",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Juan O.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Victor Vargas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Beth W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Good",
        "service_type": "Garage Door Opener",
        "tech_name": "Brenda F.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Robert Mosley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elijah B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Daniel Morton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Vickie A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Leonard Nichols",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kevin D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Leslie Campbell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Donna Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gabrielle M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Tina Lynch",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Samantha M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Edwards",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anthony B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Michael Huff",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Victoria M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Thomas Vasquez",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexander D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Sara Hall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gabriel W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Stephanie Clark",
        "service_type": "Garage Door Track Repair",
        "tech_name": "George H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Latoya Sullivan",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cassandra W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-21",
        "review_rate": 5.0,
        "customer_name": "Daniel French",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kimberly N.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Tracie Patton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Richard D.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Mrs. Stephanie Robinson MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Joshua R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Kathleen Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Craig S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Ashley Young DVM",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Marissa W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Karen Ayala",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeremiah W.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Brandon Crawford",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Leslie A.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Alyssa Alvarez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Vernon G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Krystal Gordon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lisa B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Linda Roberts",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "David Davenport",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kerry L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Nicole Jensen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Wendy S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Jessica Osborn",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Hannah Wiley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Theresa W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Tiffany Gonzalez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jordan Y.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Erik Wright Jr.",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Anthony P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Kevin Henderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Eric F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Scott Lowe",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Audrey Sanchez",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Steven Martin",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shannon W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Sean Logan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Theresa D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "John Hernandez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrea D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Jason Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Kevin G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Seth Watson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daniel K.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Ross",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rodney T.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Brian Robinson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Donald M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Cory Jackson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ashley F.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Tiffany Jackson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Julie Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Sarah Medina",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Courtney J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Thomas Cooper",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Adam W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Jacob Shaw",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Jay Morales",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Lisa Conner",
        "service_type": "Garage Door Services",
        "tech_name": "Bradley M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Donald Pearson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bridget S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Erica Thompson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Bradley S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "Gregory Carter PhD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Aaron Joyce",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Erin E.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Megan Sexton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Casey Brooks",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Natalie Y.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Amanda Cabrera",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Caleb L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Joseph Gray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gloria H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Mark Webster",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kayla J.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Mitchell Turner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Leah C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Ryan Hardy",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Paul C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Kimberly Myers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Walter S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Paula Foster",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Victoria Freeman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brent M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Kathleen Lewis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephen H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Kimberly Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffery Y.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Michelle Hughes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jake B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Dr. Robert May Jr.",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mary T.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Maria Powell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Tammy Ruiz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Julie R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Rebecca Ruiz",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Terri W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Patricia Brewer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mary M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Derek Lopez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brian E.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Terry York",
        "service_type": "Garage Door Repair",
        "tech_name": "Daniel C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Jon Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Emily Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Charlotte W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Katie Cross",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "David S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "William Valdez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Karen C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Raymond Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Jason B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Eric Paul",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melinda B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Kayla Adams",
        "service_type": "Garage Door Repair",
        "tech_name": "Nancy M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Carrie Vasquez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Maxwell R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Lori Day",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Veronica B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "William Cook",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mary S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Susan Owens",
        "service_type": "Garage Door Repair",
        "tech_name": "Nicholas A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Johnny Coleman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Janet H.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Dr. Chris Webb",
        "service_type": "Garage Door Installation",
        "tech_name": "Kevin T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Casey Turner",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Kimberly Thomas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Garrett G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Philip Chase",
        "service_type": "Garage Door Opener",
        "tech_name": "Tracy D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Michael Sparks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jose C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Christina Perry",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shelby C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Matthew Swanson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Pamela M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Casey Dawson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Edward W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Vanessa Harper",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Peter E.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Ryan Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nancy B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Joseph Barrett",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jerry H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Nicole Weeks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Craig Carey",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Samantha F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Crystal Figueroa",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cassandra B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Katherine Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sonya R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Whitney White",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cristian B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Nicole Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tyrone W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Allen Singh",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Doris J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "John Jackson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Thomas West",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ashley B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Alexis Wu",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael F.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Paula Gentry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rachel H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Gina Thompson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brendan W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Scott Kelley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "David H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Danny Garcia",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Elizabeth H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Bruce Myers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Douglas Lloyd",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Joanne Austin",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Roy N.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Megan Mathews",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ricky R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Cory Lopez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Raymond Morgan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rebekah A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Stephanie Horn",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Shelby Larson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Timothy P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Lindsay Silva",
        "service_type": "Garage Door Installation",
        "tech_name": "Bruce J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Michael Cowan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lori P.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "John Lawson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Charles H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Tiffany Norris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jared P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Beth Turner",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amy G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Mandy Herring",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joseph D.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Linda Holmes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adam P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Brittany Gonzalez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tim C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Andre Collins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Eugene S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Christopher Kelly",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Linda A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "John Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Misty W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Jonathon Powell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Jesse Boyle",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Alejandro Burns",
        "service_type": "Garage Door Insulation",
        "tech_name": "Charles L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Kathleen Sanchez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eric J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Jonathan Scott",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Justin H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Mitchell Evans",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michelle H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Jonathan Cross",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexis O.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Tami Jenkins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrea E.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Nicholas Powers",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Tiffany Carlson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mike J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Margaret Alvarez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Erin L.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Dylan Wells",
        "service_type": "Garage Door Services",
        "tech_name": "Madeline S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Rebecca Martinez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melissa Z.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Gina Shields",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tristan R.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Nathan Bray",
        "service_type": "Garage Door Services",
        "tech_name": "Chad M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Roberto Perez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicholas E.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Ryan Myers",
        "service_type": "Garage Door Services",
        "tech_name": "Sara M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-04",
        "review_rate": 5.0,
        "customer_name": "Stephanie Trevino",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carol R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Wayne Burch",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Matthew Schmidt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Charles R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Matthew Roberson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Paula R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "Antonio Nash",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Steven Mann",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Bailey R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.8,
        "customer_name": "Leslie Phelps",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Matthew C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Richard Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jack M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Philip Washington Jr.",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Melody Spence",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Chelsey M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Mrs. Marie Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Darrell W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Mary Graves",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ryan C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Jamie Bean",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Raymond I.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Robert Sanchez",
        "service_type": "Garage Door Installation",
        "tech_name": "Susan T.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Joshua Sullivan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Angela Y.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Nicole Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Laurie M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Sarah Cox",
        "service_type": "Garage Door Opener",
        "tech_name": "Yolanda T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Mrs. Brooke Perkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Daniel K.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Timothy Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Allison G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Roberto Herman",
        "service_type": "Garage Door Installation",
        "tech_name": "Erika O.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Rebecca Harrell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Eric H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "George Mills",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth B.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Mr. Sean Thomas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alyssa H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Kathleen Turner",
        "service_type": "Garage Door Opener",
        "tech_name": "Thomas A.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Walter Brown",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Drew B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Joanna Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Samantha W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Martha Welch",
        "service_type": "Garage Door Off Track",
        "tech_name": "Morgan S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Michael Hensley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Bruce M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Kristen Aguilar",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Antonio G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Daniel Riley",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brandon R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Angela Little",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "John A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Kelly Taylor",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Kristen Charles",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amber G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "John Mcclure",
        "service_type": "Garage Door Opener",
        "tech_name": "Jorge W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Kathleen Greene",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel O.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Michelle Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Eric R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "John Rivera",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Xavier S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Christopher Burch",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Juan W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Candace Gregory DDS",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Becky P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Alexandra Lozano",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adrian F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Nathan Santiago",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Mr. Richard Gay DDS",
        "service_type": "Garage Door Services",
        "tech_name": "Samuel L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Juan Nguyen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amber M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Vanessa Cordova",
        "service_type": "Garage Door Opener",
        "tech_name": "Micheal P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Whitney Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Luis R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Rebecca Stephens DDS",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jason S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Katie Harris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brandy M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Dr. Stephanie Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sharon P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Autumn Burns",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jonathan H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Lisa Roberts",
        "service_type": "Garage Door Repair",
        "tech_name": "Jared C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Richard Werner",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jamie D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "David Lee",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tara D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Daniel Jackson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shelley W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Gerald Burton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Samantha F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Theresa Brooks",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert E.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Michael Hester",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Maria M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "John Hudson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gary W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Eric Robles",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephen P.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Joy Oconnell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Richard V.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Tyler Pennington",
        "service_type": "Garage Door Services",
        "tech_name": "John O.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Brent Espinoza",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Timothy J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Darren Burgess",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeremy M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Joseph Johnson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Angela Espinoza",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lynn A.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-21",
        "review_rate": 5.0,
        "customer_name": "Catherine Carlson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amber D.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Robert Marshall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephen M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Kyle Ware",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sarah C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Joseph Navarro",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Matthew B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "James Anderson III",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Allison H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Whitney Harris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Eric E.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Beverly Dickerson",
        "service_type": "Garage Door Installation",
        "tech_name": "Amanda D.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Kenneth Harvey",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Patrick Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Stephen Stevenson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Erin Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kristin G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Amber Porter",
        "service_type": "Garage Door Services",
        "tech_name": "Suzanne M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Andrea Wolf",
        "service_type": "Garage Door Opener",
        "tech_name": "Theresa T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Alejandro Bender",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lindsey G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Steven Morales",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicholas S.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Peggy Cantu",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Adam G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Alexandra Hicks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Valerie W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Jones",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda W.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Matthew Stewart",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Victoria P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Paula Moore",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Jerome Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michele M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Walter Henry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Christopher Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffrey M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Brittany Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kristin Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Sara Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Charlotte B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Debra Colon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Aaron P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Robin Winters",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Micheal W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "James Baldwin",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Nicole Baker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Paula M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Michelle Middleton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gina M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Mr. Andrew Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Connie M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Crystal Fritz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kayla S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Jenny Robertson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Robert W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Laura Peck",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Parker C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Peter Phillips",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Helen P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Kim Stewart",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dennis C.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Kyle Young",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Valerie S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Eric Beck",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heidi H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Leslie Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Douglas P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Michael Hoffman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mary E.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Derek Lawrence",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Amber Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "David Camacho",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Malik P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Sarah Wood",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Tracy Lyons",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tanner O.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Angela Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jennifer S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Parrish",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sara Y.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Tara Phillips",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jared T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Michael King",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christina M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Ashley Valentine",
        "service_type": "Garage Door Insulation",
        "tech_name": "Karla N.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Joshua Chambers",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephen S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Jeremy Nguyen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nancy L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "James Snow",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Debbie S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "William Anthony",
        "service_type": "Garage Door Services",
        "tech_name": "James M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Joshua Fletcher",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Gabriel W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Karen Spence",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Pamela W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Michele Avila",
        "service_type": "Garage Door Insulation",
        "tech_name": "Angela C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Tracie Moore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Barbara J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Regina Lee",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Derek M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Daniel Curry",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daniel W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Allison Hall",
        "service_type": "Garage Door Off Track",
        "tech_name": "Crystal S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "Malik Burke",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Danielle N.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "David Lowe",
        "service_type": "Garage Door Insulation",
        "tech_name": "Courtney H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Kathleen Silva",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amanda W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Kevin Meyers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sean M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Roger Weber",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "James L.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Kevin Sims",
        "service_type": "Garage Door Installation",
        "tech_name": "Regina M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Stephen Hill",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicholas H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Leslie Harris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ashley H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Jessica Joseph",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew L.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Caroline Green",
        "service_type": "Garage Door Off Track",
        "tech_name": "Donald W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Amy Torres",
        "service_type": "Garage Door Installation",
        "tech_name": "Scott A.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Christine Pitts",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Craig J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Bridget Nicholson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paul G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Amanda Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Andrew Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jon S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Michael Combs",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Robert Ford",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Dana Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven E.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Amanda Dunn",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric V.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Louis James",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Kelly Knapp",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Terri G.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Christopher Ball",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amy F.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Zachary Keller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anthony C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Thomas Walter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jacqueline L.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Corey Clark III",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melissa T.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Michael Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Michaela Gonzalez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kirsten T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Michael Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jose C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Bradley Andrews",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Candace M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Kristina Martinez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michelle C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Billy Khan",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sarah S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Nichole Ortiz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Tammy Dunn",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer T.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Kimberly Blevins",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alejandro S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Gina Barr",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Douglas H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Whitney Chaney",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kelly P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Nathan Orozco",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anthony L.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Kelsey Parker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Kimberly Delacruz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tammy G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Nathan Perry",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Caitlin J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Jason Lopez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Teresa F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Katherine Jordan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Sarah Peters",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anne W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Terri Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Nathaniel B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Christina Nelson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Bryan K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Pamela Sanchez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Caroline C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "John Daniels",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Chad S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Kaylee Rios",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Margaret F.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Thomas Wilson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Allison P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Derek Parker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Duane M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Travis Hess",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tammy C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "John Thomas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Vanessa Webster",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brett J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Lucas Richard",
        "service_type": "Garage Door Services",
        "tech_name": "John B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Rachel Ward",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alyssa Z.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Kimberly Cummings",
        "service_type": "Garage Door Installation",
        "tech_name": "Patricia F.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Thomas Alvarado",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Chelsea M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Michelle Glass",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Matthew W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Christopher Haley",
        "service_type": "Garage Door Services",
        "tech_name": "Mackenzie K.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Joseph Haas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joel D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "James Fowler",
        "service_type": "Garage Door Opener",
        "tech_name": "Joy M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Steven Moore",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Jaime Stephens",
        "service_type": "Garage Door Opener",
        "tech_name": "Tonya W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Cassandra Murphy",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer G.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Ralph Higgins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jamie G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Ronald Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "Michelle B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Collins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joseph H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Susan Flynn",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sherry G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Melissa Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John J.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Kristina Jensen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeffrey R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Kathryn Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Eric K.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Kaitlin Booker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christina M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Charles Thompson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Trevor B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Dean Baker",
        "service_type": "Garage Door Opener",
        "tech_name": "Jill W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Lori Martin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Heather T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Hannah Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Johnny E.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Timothy Black",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Julie P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Raymond Blake",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Dr. Miguel Dennis MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brianna H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Jessica Orr",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Trevor B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Brent Turner",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Christine Salazar",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Pamela Briggs",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Curtis O.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Jeremy Perez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Harold G.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Christopher Mitchell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Ian Lee",
        "service_type": "Garage Door Repair",
        "tech_name": "Tara W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Steven Perez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Linda R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Nicole Velazquez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brad G.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Vicki Holmes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joshua H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Rachel Cruz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tyler F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Mrs. Jessica Curtis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Natasha N.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer Simon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "John M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Michael Washington",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jennifer B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Sara Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Susan Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Carrie B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Brianna Curry",
        "service_type": "Garage Door Insulation",
        "tech_name": "Thomas J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Dr. Carla Dunn",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeremy M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Tyler Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kenneth B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Michael Lynch",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jasmine T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Amanda Garcia",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kenneth R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Alejandro Richardson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Paul Nunez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacqueline G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Madison Stein",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kara C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "William Sharp",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heather C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Amanda Adams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristen J.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Tyler Barnett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Richard P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Justin Browning",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Thomas M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Logan Gomez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gary T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Sandra Dickson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Keith G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Henry Bryan",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Mary Love",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Elizabeth D.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Tracy Alvarez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Francisco S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Francisco Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amy H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Kelly Gray",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kyle D.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Patricia Sheppard",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Miguel J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Ricardo Gutierrez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carlos B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Debra Gonzales",
        "service_type": "Garage Door Repair",
        "tech_name": "Tanya O.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "John Phillips",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Meagan G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Candice Davidson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Adam P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Susan Hayes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rachel A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Stacey Coleman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jenny S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "John Cain",
        "service_type": "Garage Door Opener",
        "tech_name": "Karen W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Scott Cole",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anna P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Robert Maxwell",
        "service_type": "Garage Door Services",
        "tech_name": "Austin J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Thomas Arnold",
        "service_type": "Garage Door Repair",
        "tech_name": "Kelli M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Nguyen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robin P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Sandra Whitney",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Rachel Gomez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrea G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Catherine Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joseph G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Jill Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jamie A.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Roger Benson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicholas J.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Diana Leon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stacey O.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Denise Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Robert Robles",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Thomas R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Renee H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "John Rios",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Scott H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Alexandra Henry",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Mr. Ricardo Willis",
        "service_type": "Garage Door Repair",
        "tech_name": "Jennifer C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Mrs. Angela Murray DDS",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Margaret H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Sandra Swanson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lauren B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Jessica Robinson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeffery G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Anita Lee",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Victoria S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Jay Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stacey S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Thomas Nguyen",
        "service_type": "Garage Door Opener",
        "tech_name": "Sean P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Robert Sanchez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Deborah M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Tyler Palmer",
        "service_type": "Garage Door Services",
        "tech_name": "Aaron P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "William Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jane P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Robert Willis",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Karen Parsons",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Jennifer Cordova",
        "service_type": "Garage Door Opener",
        "tech_name": "Laurie S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Joseph Hart",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrea M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Kathleen Webb",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Susan B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Dustin Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lauren S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Jeffery Kelley",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elijah Y.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Kelsey Morris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Todd R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Carrie Campbell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sarah B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Matthew Joyce",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Wendy Johnston",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Vickie T.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Tonya Fleming",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christine J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Katrina Taylor",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Misty W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Wyatt Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jessica J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Aaron Luna",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Howard Norton",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew O.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Norman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tammy B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Jennifer Bryant",
        "service_type": "Garage Door Opener",
        "tech_name": "Scott N.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Wanda Levine",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dakota H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "David Wilkins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Taylor Castaneda",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shirley T.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Karen Nicholson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "James Walsh",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tammy B.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Jennifer Rodriguez DVM",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Carrie Warner",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sydney W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Steven Collier",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jason S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Fred Burns",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffrey R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Amber Mcgee",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tara S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Leah Allen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "David Thornton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nathaniel V.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Donna Fisher",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lori C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Robert Fisher",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Charles C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Amanda Grant",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Christina Parker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brad L.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Karina Lewis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Wendy B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Jocelyn Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Leslie P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Jennifer Peters",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Allison C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Lindsay Robinson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Curtis J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Benjamin Aguilar",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tina Y.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "John Anderson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mallory S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Debra Cole",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Jacqueline King",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Steven H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Amy Bailey",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Victoria H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Kevin Wagner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Courtney Y.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Jack Cherry",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Dr. Joseph Lowe",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Julie Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Kristin Copeland",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Philip R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "Randy Bolton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Chad C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Ryan Chung",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Joshua Schroeder",
        "service_type": "Garage Door Services",
        "tech_name": "Emily T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Paula Kelly",
        "service_type": "Garage Door Repair",
        "tech_name": "Deborah J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Erin Moreno",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Steven W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Cameron Norris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kristen M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Gregory Holt",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christy B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Megan Gibson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Walter M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Daniel Stewart",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Theresa M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Roger Page",
        "service_type": "Garage Door Installation",
        "tech_name": "Autumn A.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Ronald Harper",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melissa H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Rebekah Cole",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kelly A.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "James Obrien",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "James P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Rebecca Chase",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Katie N.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Sandra Martin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Valerie Sanchez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "George F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Shirley Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christopher L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael G.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Chelsey Lara",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tom J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Jonathan Morris",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ann K.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Dale Duran",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Dr. Leah Santiago",
        "service_type": "Garage Door Opener",
        "tech_name": "Christine W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Walton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Wendy W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Heather Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mary H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Michael Thompson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashley S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Brandon Russell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Sharon Krause",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Jody Reid",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph R.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Veronica Daniels",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandon G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Daniel Black",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amber R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Patrick Ferrell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Barbara M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Manuel Marquez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Steven C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "George Jones",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Megan Fowler",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrew S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Lori Taylor",
        "service_type": "Garage Door Repair",
        "tech_name": "Kimberly B.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Rodney Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Danielle S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Tanya Deleon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Tiffany Burns",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ian L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Michelle Wheeler",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alejandro G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Mark Gonzalez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Adrian H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Randall Wagner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cynthia S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Denise Henson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kelly M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Jonathan Gibson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Thomas H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Carrie Kirby",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Tony Rose",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Natalie B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Alicia Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kristy C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Shelley Arroyo",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Gwendolyn B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Charles Weber",
        "service_type": "Garage Door Opener",
        "tech_name": "Amber M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Tammy Lucas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Juan G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Betty Lowe",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Anna G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "William Jones",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Donald Richardson",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Barbara Tran",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nancy B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "John Mora",
        "service_type": "Garage Door Services",
        "tech_name": "Rickey W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Justin Sanchez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erin M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Kristin Barker",
        "service_type": "Garage Door Installation",
        "tech_name": "Joseph M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Jeffery Medina",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tina E.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Suzanne Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Ronnie S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Dr. Renee Pugh",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Scott B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Jason Jackson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Suzanne P.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Lisa Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy N.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Don Diaz",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nathaniel H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Alejandro Fox",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Manuel C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Rebecca Obrien",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicole W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Jennifer Conrad",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sandra M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Laurie Anderson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cheryl W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Wendy Mcdonald",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brooke B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Gregory Charles",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jose C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Leon Tyler",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Brenda Long",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Teresa B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "John Nelson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sharon G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "James Klein",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Christine Lewis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Samantha H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Carolyn Wright",
        "service_type": "Garage Door Opener",
        "tech_name": "Jacob G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Jeremy Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dana C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Jason Edwards",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sara B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "April Pena",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Sheryl Thomas",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Linda M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Jessica Anderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steve M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Richard Edwards",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Melanie B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-03",
        "review_rate": 5.0,
        "customer_name": "Cheyenne Moore",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Austin H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Michael Dorsey",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Angela T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Robin Collier",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lori C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Lauren Burgess",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sarah J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Samuel Ruiz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ashlee M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Rebecca Reed",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Shannon Marquez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Denise W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Melanie Thornton",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Thomas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Maria J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Angelica Scott",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Anthony Burgess",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rebecca G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Jason Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jordan C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Judy Dean",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tracey H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Paul Mcdaniel",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ricky B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Jennifer Heath",
        "service_type": "Garage Door Repair",
        "tech_name": "William S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Paul Madden",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Jonathan Moore",
        "service_type": "Garage Door Opener",
        "tech_name": "Nancy W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Matthew Silva",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ryan R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Lee Soto",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Megan C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Erica Mitchell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher V.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Mary Madden",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashley C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Debra Burns",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Chad Calhoun",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alexandria S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Eric King",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amber G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Christopher Clay",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tina W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Brandon Phillips",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Geoffrey M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Shannon Norris",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katherine S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Omar Tucker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tara H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Jessica Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erin C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Mark Perez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrea C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Angie Simpson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Valerie N.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Frank Walter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donna S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Bradley Little",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brett B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Christina Murphy",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Katie Y.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Ronald Richard",
        "service_type": "Garage Door Installation",
        "tech_name": "Dylan T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Steven Armstrong",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Christopher Fletcher",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Raymond H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Alexa Hunt",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Andrew Hardin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Benjamin J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Ashley Mccann",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Roger R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Ross",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sergio T.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Karen Robinson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tyler E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Kenneth Evans",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sean M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Crystal Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sheila B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Susan Stanley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Lori Bradshaw",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Caleb G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Gregory Martinez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melissa M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Anthony Sutton",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "John G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Robert Fernandez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Garrett W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Rebecca Bell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Garrett H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Eric Donaldson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Monique S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Nicholas Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "David Maynard",
        "service_type": "Garage Door Installation",
        "tech_name": "Austin G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Jacob Griffith",
        "service_type": "Garage Door Services",
        "tech_name": "Daniel B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Paula Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Zachary Charles",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joseph F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Sarah Cook",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Tyler Parker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Erika F.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Laurie Hammond",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Micheal C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Denise Ramirez",
        "service_type": "Garage Door Services",
        "tech_name": "Trevor H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Angela Ward",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeremy A.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Christy Martinez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicole H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Phillip Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Francisco M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Christine Mcbride",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brandi H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Eric Dunn",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melissa P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Richard Mills",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joseph M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Gina Jones",
        "service_type": "Garage Door Services",
        "tech_name": "Jennifer C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Gomez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samantha S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Edward Manning",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffrey A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Joe Brown",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cynthia J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Carol Jimenez",
        "service_type": "Garage Door Installation",
        "tech_name": "Gerald G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "James Carrillo",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Krista Brock",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kimberly A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Kelsey Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Charles G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Michael Huynh",
        "service_type": "Garage Door Opener",
        "tech_name": "Bryan M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Leah Perez",
        "service_type": "Garage Door Repair",
        "tech_name": "Justin M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Bonnie Graham",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel L.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Noah Moreno",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "John Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brittany S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Richard Howell",
        "service_type": "Garage Door Services",
        "tech_name": "Adrian A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Angel Anderson",
        "service_type": "Garage Door Services",
        "tech_name": "Brandon P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Samuel Zimmerman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Eddie Guzman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Timothy H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Cindy Fisher",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brandon S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Michael Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Elizabeth S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Juan Allen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Lisa Sanchez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bryan V.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Keith Owens",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melinda S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Richard Hill",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Matthew N.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Denise Gardner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angela C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Erin Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kristy D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Tiffany Williamson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Bryan F.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Mary Hoffman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Carrie S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Robert Aguirre",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Grant D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Sarah Daniels",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brianna H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Stacey Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Wesley S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Michael Ali",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Maria C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Lisa Sanders",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Laura B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Randy Jones",
        "service_type": "Garage Door Services",
        "tech_name": "James V.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Kimberly Nelson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dennis M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "James Schroeder",
        "service_type": "Garage Door Repair",
        "tech_name": "Gabriella R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "John Smith MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Mr. Brian Wong",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Frank L.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Sherry Thompson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anna C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Kristin Young",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Matthew Wyatt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Neil G.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Desiree Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Teresa W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Lopez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Natalie H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Amber Rivas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John O.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Mario Richardson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Felicia H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Lisa Williams",
        "service_type": "Garage Door Repair",
        "tech_name": "Raymond L.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Jacob Davis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Peter M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Laura Tran",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alexandra A.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Tina Montoya",
        "service_type": "Garage Door Installation",
        "tech_name": "James A.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Lisa Baker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Derek Cox",
        "service_type": "Garage Door Off Track",
        "tech_name": "Eduardo E.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "William Clark",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Chris W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Allison Cox",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kenneth G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Sheila Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paul D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Steve Robertson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rhonda C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Kara Nunez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Amanda Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dustin V.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Melissa Goodman",
        "service_type": "Garage Door Repair",
        "tech_name": "Carla B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Anna Perry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew L.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Raymond Phillips",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Mrs. Tonya Medina",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Frank G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Kenneth Kemp DVM",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stephen B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Erin Harrell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brandy J.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Eric Rowe",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kathryn F.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Mike Gibson DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Kelly King",
        "service_type": "Garage Door Installation",
        "tech_name": "Steven P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Brittany Francis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Sandra Harvey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Darren G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "James Woods",
        "service_type": "Garage Door Services",
        "tech_name": "Darren L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Lauren Rosales MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher P.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Tara Brooks",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kathryn G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Patrick Sparks",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Karen Bautista",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jared F.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Brendan Turner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Deborah S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Brian Simmons",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Randy K.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Chad Scott",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Frank S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Carpenter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julie M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Latoya Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kelly F.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Tara Moore",
        "service_type": "Garage Door Off Track",
        "tech_name": "Francis F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Daniel Camacho",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Travis G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Jennifer Hubbard",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Parker P.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Cassandra Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michele A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Tracy Garcia",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sherry S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Casey Mcpherson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Shane W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Matthew Mitchell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jason S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Don Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cody Z.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Crystal Hawkins",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michelle G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Shawn Graham",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Micheal L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Lee Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Anna Barrera",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lori P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "David Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kayla K.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Shelley Blair",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Susan V.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Charles Strong",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alexandria M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Patrick Larson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jacob S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Chelsea Barker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Pamela G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Cynthia Mills",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kaitlyn T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Cook",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joshua W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "James Allison",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kristen M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Bryan Lewis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dylan M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Edward Ramos",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer N.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Wanda Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Taylor J.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Douglas Ritter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Austin C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Thomas Byrd",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alex N.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Adam Fernandez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Teresa C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Margaret Cabrera",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Christine H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Mary Black",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Debra Sanders",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Courtney P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Heather Carpenter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Debra H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Christopher Pittman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dennis G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Julie Owens",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Austin G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Jennifer Mcdaniel",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brett L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Tiffany Jackson",
        "service_type": "Garage Door Repair",
        "tech_name": "Vicki G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Billy Murray",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Linda Collins",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard G.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "John Wilson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lorraine P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Antonio Jacobs",
        "service_type": "Garage Door Insulation",
        "tech_name": "Timothy S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "James Hatfield",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Miss Veronica Burns",
        "service_type": "Garage Door Services",
        "tech_name": "Peter A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Brandon Mcclure",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Gina G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Victoria Yoder",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jason F.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Katherine Solis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jeffery W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Matthew Castro",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Renee H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Sabrina Montoya",
        "service_type": "Garage Door Off Track",
        "tech_name": "William J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-07",
        "review_rate": 5.0,
        "customer_name": "Megan Flynn",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacqueline S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Little",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Samantha Gonzalez DVM",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Jeremy Swanson",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian K.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Henry Ayala",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Hector A.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Amy Vang",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Travis M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Andrew Thompson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Meredith W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Jennifer Alvarez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ricardo D.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "John Salazar",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jonathan M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Nicole Wagner",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Candice M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Vanessa Warren",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James T.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Peter Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Eric C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Ricardo Anthony",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Darrell S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Brian Mitchell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Crystal G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Wesley Wells",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Barbara Spencer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Erika Wall",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jim Y.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Adam Barnes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rodney H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Craig Chavez",
        "service_type": "Garage Door Services",
        "tech_name": "Robin D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Thomas Parker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Roberto A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Angela Lee",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dana S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Natalie Conway",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Amy Hughes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jeanne W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Deborah Jenkins",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Linda N.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Kaitlin Wagner",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Edward M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Joshua Willis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lance O.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Christopher Avery",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Katherine C.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Nicholas Michael",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jacqueline H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Stacy Pennington",
        "service_type": "Garage Door Insulation",
        "tech_name": "Cassandra C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Rebecca Patel",
        "service_type": "Garage Door Repair",
        "tech_name": "Leslie K.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Matthew Butler",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexander P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Joshua Sanders",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Craig H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "John Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "John Mcgrath",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Edward H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Miss Rebecca Gaines",
        "service_type": "Garage Door Services",
        "tech_name": "Angelica C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Corey Green",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Luis W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Patrick Rogers",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Catherine S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Walter Cox",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michele T.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Tracey Jacobs",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Gregory Friedman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Cody Marquez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lori R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Laura Turner",
        "service_type": "Garage Door Repair",
        "tech_name": "Tiffany V.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Lisa Perez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Cynthia Wolf",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Summer W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Terri Nielsen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emily B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Beth Adams DDS",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Dominic Villa",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Vanessa F.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Parker Cowan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bobby C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Amanda Foster",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Chelsea K.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Kirk",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Sandra Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Julie P.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Glenda Young",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Tammie Ward",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lauren P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Sara Wilson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Francisco C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Steven Charles",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alexandra R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Andrew Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kenneth S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Bruce Valentine",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashley H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Joshua Welch",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephen L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Jesus Gamble",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Samuel P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Michael Nichols",
        "service_type": "Garage Door Services",
        "tech_name": "Paul M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Matthew Roth",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Keith Sanders",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tiffany L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Alex Hansen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kaitlin Y.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Kevin Garcia",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Edward V.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Andrea Gould",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tiffany C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Kendra Montoya",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Victoria Stafford",
        "service_type": "Garage Door Services",
        "tech_name": "Victor S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Angela Martin",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Laura S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Laura Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Craig W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "James Chandler",
        "service_type": "Garage Door Installation",
        "tech_name": "Erin C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Martinez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicole H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Jennifer Simon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Morgan D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Erika Hernandez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Angelica J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Blair",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Diane C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Sarah Golden",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ricky T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Kenneth Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joyce G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Rebecca Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sherri M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Timothy Snyder",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Johnathan L.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Suzanne Rich",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Allison K.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "William Barrett",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole V.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Brian Rivas",
        "service_type": "Garage Door Installation",
        "tech_name": "Alan B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Lauren Mccarthy",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Scott Bryant",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Daniel R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Alexandra Martinez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Richard O.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Brenda French",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Sara Hudson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Edgar D.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Ronald Knight",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Daniel M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Mike Schultz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Heather H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Douglas Perez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Carol S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Jay Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jack D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Calvin Stewart",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gregory G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Emily Strickland",
        "service_type": "Garage Door Services",
        "tech_name": "Jonathan S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Nicholas Burns",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa P.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Mark Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "James Hill",
        "service_type": "Garage Door Opener",
        "tech_name": "Christie S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Ian Ayers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jose G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Jennifer Alvarez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jonathan W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Jonathan Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Steven G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Paul Davidson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Christopher Tapia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Juan M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Olivia Roman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Samuel Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Megan S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Samantha Hawkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mariah H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Alejandra Estrada",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Heidi N.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Mary Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.8,
        "customer_name": "Nathan Arroyo III",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anna S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Donald Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Chad C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "James Gibson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Brittany Parker",
        "service_type": "Garage Door Installation",
        "tech_name": "Robert V.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Gabriela Clark",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Taylor Reeves",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shawn W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Angela Shannon",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Barbara Morris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amanda F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Amanda Mcdonald",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Thomas T.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Vanessa Woods",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "April A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bates",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amanda W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Karen Martinez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Loretta Taylor",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Destiny M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Dr. Marcus Foster",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Leroy L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Mitchell Sherman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Samuel M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Michael Rivera",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Misty Sullivan",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy Y.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Tamara Byrd",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Deborah R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Stacy Schultz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Michael Case",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Steven P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Micheal Baker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anthony R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Brittany Barker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Molly C.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Erin Moore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Dorothy Joseph",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Meghan V.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Sarah Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patrick L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Jacob Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patricia R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Jessica Pittman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Heather Vega",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Adam G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Jacob Mccarthy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Gabriela Freeman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael L.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Jeanne Kennedy",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ashley S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Steve Perez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Eric P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Kathy Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Laura Sanchez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kyle T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Gabrielle Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Connie T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "John Combs",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gregory M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Cory Anderson",
        "service_type": "Garage Door Repair",
        "tech_name": "Katherine H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Heather Lawrence",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Angelica M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Carol Scott",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Victor B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Mr. Christopher Martin II",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nancy B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Mr. William Roman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jasmine G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Jessica Lamb",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Chad D.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "John Owens",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrew L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Dawn Mendez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Carmen French",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Antonio L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Brian Wiggins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Shannon Jackson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katie M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Miranda Cohen",
        "service_type": "Garage Door Opener",
        "tech_name": "Jimmy J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Peter Padilla",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephen W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Tony Beck",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dakota W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "Desiree Carter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Crystal D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Kara Burns",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samuel T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "April Stewart",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amanda D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Mark Allen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brandon S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Daniel Gordon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Heather T.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Shane Munoz",
        "service_type": "Garage Door Repair",
        "tech_name": "Lauren R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Michael Thompson",
        "service_type": "Garage Door Opener",
        "tech_name": "Gregory S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Sonia Yang",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michelle A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Matthew Mendez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kristen P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Christine Thomas",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Autumn Hall",
        "service_type": "Garage Door Opener",
        "tech_name": "Ricky L.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Sara Garcia",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Taylor J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Anthony Williams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Chris H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Stacy Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Karina H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Leroy Rowe",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Deborah S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Robert Martin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Courtney S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-09",
        "review_rate": 5.0,
        "customer_name": "Victoria Barnes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Abigail Riggs",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Chad A.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Curtis Edwards",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mariah W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Sara Miller",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mark L.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Lauren Black",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew N.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Kathryn Sanchez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Elizabeth P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Amanda Santos",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.9,
        "customer_name": "Michael White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shannon R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Jose Randall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "James Mathews",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jill H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Ross",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Bianca H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Melissa Morales",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Williamson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gregory H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Henry Rogers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Nicholas Gonzales",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christina H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Robert Suarez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Adam T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Brian Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Seth W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Kayla Sheppard",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joanna S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Amanda Martinez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Trevor W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Stephen Rios",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Angela S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Bryan Lowery",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brooke G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Julie Boyd",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ryan R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Larry Carter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brandon S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Anthony Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mercedes R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Ryan Vaughn",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Charles W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Robert Olsen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Dawn Russell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Valerie Fitzpatrick",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rachael M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Robert Chavez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lindsay H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Dr. Adam Sosa",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stuart H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Bradley Williams",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Julie Sellers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Meagan T.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Edward Reilly",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Yolanda Clark",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicholas M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Teresa Gray",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Heather F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Maria Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Katie J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Evan Tapia",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Roberto S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Joseph Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Terrence W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Eric Wilkerson",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Melissa Orr",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashley N.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Trevor Stafford",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Nancy Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dustin M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Ms. Jessica Velez",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Bryan Cook",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Paul Woodward",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Cheryl Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Michael Strickland",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cassandra P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Christopher Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Marilyn H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Frank Jimenez",
        "service_type": "Garage Door Opener",
        "tech_name": "Kristen H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Mr. Richard Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Melanie H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Michele Wade",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Allison W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Christopher Wyatt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Austin Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rhonda L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Joseph Nichols",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeffrey R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Chad Adams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jasmine F.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Lori Hall",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott N.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.8,
        "customer_name": "Justin Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ana F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-25",
        "review_rate": 5.0,
        "customer_name": "Wendy Fletcher",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Blake O.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Benjamin Taylor",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cindy B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Adam Pearson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Allison G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Lisa Morales",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alexis M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Scott Bates",
        "service_type": "Garage Door Services",
        "tech_name": "Linda C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Lisa Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Travis S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Mr. William Roberts",
        "service_type": "Garage Door Opener",
        "tech_name": "John S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Hannah Patterson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly J.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Sharon Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mitchell L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Mike Zamora",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kenneth N.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Grant",
        "service_type": "Garage Door Repair",
        "tech_name": "Katelyn D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Megan Adams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Deborah N.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Michael Caldwell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jessica H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Jason Harris",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephanie B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Elijah Duncan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alexander W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Nathaniel Case",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mathew T.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Brian Meyer",
        "service_type": "Garage Door Insulation",
        "tech_name": "Thomas D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Kimberly Nunez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Amanda B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Melissa Vance",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Regina R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Andres Watson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Melissa L.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Sarah Hall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Scott C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Brittany Wright",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shawn V.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Andrew Lopez DDS",
        "service_type": "Garage Door Services",
        "tech_name": "Amanda L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Allen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samantha R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Melissa Harris",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Megan H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Jennifer Richards",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Theresa A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.8,
        "customer_name": "Lee Schaefer MD",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Glenn T.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Morgan Hart",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Taylor Hogan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Mark W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Melissa Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Logan R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Brandon Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Sarah Ruiz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Samantha W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Christine Villa",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kelli S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Benjamin Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jonathan W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Todd Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicholas K.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Robert Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "John Woodard",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Thomas Hogan MD",
        "service_type": "Garage Door Installation",
        "tech_name": "Margaret R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Nicole Martin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Michael H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Ronald Vargas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Juan Stevens",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bruce B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Andrea Shaffer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Thomas C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Karen Cox",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brenda R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Erin Sullivan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chelsea G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Karen Garner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sean K.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Jeremy Ruiz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Peter Yu",
        "service_type": "Garage Door Off Track",
        "tech_name": "Laurie K.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Joseph Peterson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Emily Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "April H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Laura Deleon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Alejandro King",
        "service_type": "Garage Door Installation",
        "tech_name": "Jared F.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "William Thompson",
        "service_type": "Garage Door Repair",
        "tech_name": "Darlene W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Maria Mosley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stacy L.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Nancy Myers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ruben Z.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "David Baker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brent M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Isaac Phillips",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Louis R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Gerald Riddle",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kevin G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joshua C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Tara Little",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christine C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Mrs. Penny Adkins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Karen L.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-15",
        "review_rate": 5.0,
        "customer_name": "Paul Vazquez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Derrick A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Angela Garner",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary D.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Theresa Anderson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sergio R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Richard Newman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rebecca P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.8,
        "customer_name": "Samantha Mueller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gary N.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Jim Tran",
        "service_type": "Garage Door Installation",
        "tech_name": "Evan W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Evans",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kristina D.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Ian Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kyle N.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Amy Kline",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tanya C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Wayne Scott",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Amy May",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Lauren Mckee",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jessica C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Michael Edwards",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Karen E.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Ronnie Gibson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mia R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Timothy Armstrong",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sarah H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Kristy Williams",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kevin M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Brian Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrea M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Dr. Grace Hammond",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jared S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Steven Vaughn",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Larry C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Richard Martin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Charles C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Daniel Chang",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Abigail G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Brooke Mckee",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carol F.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Vicki Evans",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jamie D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Peter Robinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shelly S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Bobby Rowland",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Edward J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Melissa Oliver",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Tyrone Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Luke J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Alyssa Robinson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremy H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Courtney Obrien",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew P.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Claudia Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "April H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Thomas Roach",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Susan M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Jennifer Zimmerman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Kevin Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Christine Beck",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Lisa J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Linda Parker",
        "service_type": "Garage Door Services",
        "tech_name": "Jason W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Mary Whitaker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Debra P.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Melissa Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mark D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Joshua Davidson",
        "service_type": "Garage Door Services",
        "tech_name": "Victoria M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "James Murray",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Diana N.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Shawn Matthews",
        "service_type": "Garage Door Opener",
        "tech_name": "Krystal A.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Mrs. Danielle Henry",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joshua B.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Corey Pearson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Aimee Turner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas D.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Matthew Wise",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Teresa M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Teresa Cuevas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kelly N.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Roy Craig",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rhonda G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Ricardo Welch",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Matthew S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Steven Pham",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ian B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Marcus Flores",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Jay Moore",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Caleb D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Eric Mueller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Latoya G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Sean Luna",
        "service_type": "Garage Door Repair",
        "tech_name": "Lauren M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Brittany Roth",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Timothy M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Debra Norris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gary D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Samantha Stephens",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeremy W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Rebecca Kidd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kevin J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "James Ware",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Annette H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Joshua Williamson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott L.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Patrick Gray",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrew H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Christopher Velazquez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Angela R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Catherine Wilson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Travis S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Bruce Jordan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Jonathan Lewis",
        "service_type": "Garage Door Repair",
        "tech_name": "Ryan G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Sean Delacruz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Abigail W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Kathleen Russell",
        "service_type": "Garage Door Opener",
        "tech_name": "Tyler C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Rodriguez",
        "service_type": "Garage Door Repair",
        "tech_name": "Ian W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "Connor Brennan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Roger J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Jason Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brian C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Richard Page",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Horton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Clayton C.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Victoria Pierce MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sharon C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Janet Allen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bradley H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Mindy Terry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Audrey B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Sara Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jack H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.8,
        "customer_name": "Joshua Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda K.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Corey Harris",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Keith J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Karen Coleman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Morgan S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Robert Jensen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rebecca D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Robert Ortiz",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jason E.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Katherine Martin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Jackie Ramirez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Chelsea S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Mrs. Christine Taylor",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christopher W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Rebecca Wang",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Barbara Rodriguez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael D.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Matthew Moore",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Ronald Gonzalez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sharon S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Deborah Shaw",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Richard M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Michelle Wiley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Victoria Sanchez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jean W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Jasmine Cole",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Nicholas G.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Melissa Walker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Roy K.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Regina Ramirez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Linda White",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cody L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Luis Jackson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tamara W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn Wilkerson",
        "service_type": "Garage Door Installation",
        "tech_name": "Kimberly S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Katherine Long",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Virginia Thomas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephen F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Erik Lopez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brenda R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Chad Cruz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Charles B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Scott Velez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Kimberly Pratt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Erica Hahn",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "John R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Jessica Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Anthony Doyle",
        "service_type": "Garage Door Installation",
        "tech_name": "Monica R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Tammie Simmons",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lauren B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Audrey Bailey",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Juan B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "John Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Reeves MD",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Randy W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Joseph Guzman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kathy S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Tina Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brandon D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Jessica Rice",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Linda Crawford",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Robert Schneider",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffery D.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Emily Maxwell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Cynthia D.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Andre Price",
        "service_type": "Garage Door Off Track",
        "tech_name": "Phillip S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Samuel Santiago",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Raymond H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Carson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Gregory Meza",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jesse P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Christopher Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Carl L.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Ricardo Henry",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Noah B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Linda Underwood",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Abigail W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Sandra Simmons",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kathleen H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "John Kim",
        "service_type": "Garage Door Opener",
        "tech_name": "Kevin C.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Cory Pierce",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Chris Y.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Jennifer Gomez",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Katherine Brown",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Monica S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Michelle Armstrong",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Charles W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Ryan Mathis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cameron F.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Kelsey Hodges",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas J.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Joshua Rodriguez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sergio W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Cynthia Chapman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Justin G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Gregory Garza",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jill S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Jason Bartlett",
        "service_type": "Garage Door Services",
        "tech_name": "Alyssa M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Catherine Green",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Jessica Stevenson",
        "service_type": "Garage Door Services",
        "tech_name": "Leslie A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Mary Kramer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Julie H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Howard Adams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Peggy W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Patrick Brown",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "John Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Carrie S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Carol Jackson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christina K.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Ashley Douglas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Laura Davis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steve L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Donna Jackson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gary H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Mary Young",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Autumn M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Sarah Richard",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Brianna Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Timothy O.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Preston Myers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher D.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Daniel Edwards",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ricardo B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Wanda Murphy",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Samantha Scott",
        "service_type": "Garage Door Repair",
        "tech_name": "Henry Z.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Joseph Castaneda",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Donald A.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Matthew Foster",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jenna R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Curtis Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Leslie S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Heidi Flores",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Susan W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Savannah Dickson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Tyler Carpenter",
        "service_type": "Garage Door Services",
        "tech_name": "Charles B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Stephanie Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Justin W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Paula Hernandez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Scott N.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Valerie Colon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Matthew Esparza",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Derek F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Timothy Braun",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Maria P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Jessica Carpenter",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Victoria C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Cassandra Ramsey",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David N.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Tammy West",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Robert Roberts",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alyssa C.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Gail Reynolds",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Luke Harvey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Melissa Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sharon W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Nancy Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stacie A.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Adam Curry",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kathleen H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Stephanie Long",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Manuel M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Rachel Reid",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Leslie B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Scott Chandler",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ashley H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Kimberly Taylor",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Nicholas Green",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "James L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Christopher Acosta",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sandy S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Tina Casey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Luis C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Jonathan Murray",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Daniel M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Nancy Coleman",
        "service_type": "Garage Door Opener",
        "tech_name": "George P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Henry Baldwin",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "George J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Ashley Summers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Danielle L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Benjamin Ford",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandon M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Mark Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ashley A.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Barry Harris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Donna B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Fred Cox",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Ashley T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "James Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Karen O.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Dustin Rogers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sean J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Brandon Meyer",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dennis B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Kelly Hughes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tracy J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Beverly Cox",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Katherine Oneal",
        "service_type": "Garage Door Repair",
        "tech_name": "Helen C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Adrian Hansen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Carolyn G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.8,
        "customer_name": "Carlos Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tamara M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Jessica Bean",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Deborah Melendez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joshua P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Jason Paul",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "John C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Ryan Jenkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "James Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Paul B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Lauren Adams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paul F.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Tanya Ferguson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Madeline R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Kimberly Gill",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michele B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Robert Shaw",
        "service_type": "Garage Door Opener",
        "tech_name": "Cheryl D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Karen Young",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Douglas R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Kayla Anderson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Eric B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Heidi Murphy",
        "service_type": "Garage Door Repair",
        "tech_name": "Jeffery E.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Catherine Levine",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gabriel C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Cindy Hill",
        "service_type": "Garage Door Repair",
        "tech_name": "Kyle V.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Kenneth Pierce",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Arthur C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Michael Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christina B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Caleb Foster",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Donald Hoffman",
        "service_type": "Garage Door Opener",
        "tech_name": "Edward H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Michelle Weeks",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kelli P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Nancy Mills",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Alexis K.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Ruth Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kathleen R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Kevin Jarvis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alicia C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-22",
        "review_rate": 5.0,
        "customer_name": "Paul Lam",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Emily W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Douglas Bennett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrea T.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Veronica Sanchez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Laura B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Erica Thompson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael G.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Alan Marshall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bobby L.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Sharon Allen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Veronica H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Anna Guzman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joel G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "George Duffy",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kimberly R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Hannah Torres",
        "service_type": "Garage Door Opener",
        "tech_name": "Erin H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Dennis Powell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Justin W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Stacey Cross",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Shelia M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "William Hall",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christian C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Christina Cook",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Krista C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Richard Parks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.8,
        "customer_name": "Jay Riley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Patrick R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Samuel Henderson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stacy J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Alexander Watson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Angela B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Matthew Evans",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lance F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Brandon Lewis",
        "service_type": "Garage Door Repair",
        "tech_name": "Noah J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Crystal Frazier",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Donald B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Thomas Reilly",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kevin F.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-10",
        "review_rate": 5.0,
        "customer_name": "Dennis Yu",
        "service_type": "Garage Door Off Track",
        "tech_name": "Cynthia L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Megan Manning",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dylan B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Stacie Wilson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Joseph Schroeder",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicole C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Joseph Clark",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "April S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Amy Lee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Molly R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Cynthia Price",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christine W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Steven Camacho",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Aaron S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Gabriel Valdez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Deborah Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Anthony H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Monica Shaw",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dawn F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Kevin Wilson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Garrett R.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Danny Rivera",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ronald H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Sherry Morgan",
        "service_type": "Garage Door Opener",
        "tech_name": "Lindsey H.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Charlotte Harrington",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Casey M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Samuel James",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Jason Brown",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Monique A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Joshua Castro",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Peter J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Casey Rodriguez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Yvonne Sanchez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nathan H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Dennis Phillips",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Stephanie H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Jason Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dawn H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Mary Cline",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Valerie B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Harold Skinner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Catherine S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "John Tucker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "James O.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Kristin Schneider",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "William E.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.8,
        "customer_name": "Jason Wagner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristy M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Terri Michael",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Charles Frank",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael D.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Kristen Jensen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Marc P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Jason Vasquez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Sophia M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Derek Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dennis L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Justin Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sarah N.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Charles Hughes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lauren S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Laura Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lisa G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Matthew Noble",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicholas D.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "David Morse",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Sharon King",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Curtis Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "April J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Robert Garner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Margaret D.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Thomas Fisher",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Roger W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Dana Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Marco J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Alexis Hudson",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.8,
        "customer_name": "Carol Morris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sarah B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Brent Watkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Justin G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Kenneth Thompson",
        "service_type": "Garage Door Installation",
        "tech_name": "Lynn T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Anthony Adams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dawn W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Renee Conrad",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dana B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Michael Adams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Victoria S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Katherine Shelton",
        "service_type": "Garage Door Installation",
        "tech_name": "Kenneth R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Cory Brock",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Jordan Berry",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Jessica Jones",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michelle C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Gregg Peterson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Thomas Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christopher H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Arthur Patel",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ryan M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Craig Robertson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Natasha G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Rebecca Townsend",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Eddie H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Alexis Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Renee D.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Ashley Thomas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Donna Ramirez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shannon P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Daniel Kline",
        "service_type": "Garage Door Services",
        "tech_name": "Sonya W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Joe Gonzalez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ashley H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Chad Mccann",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Nicholas Mcdonald",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Carolyn W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Linda Berg",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mark W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Melinda Campbell",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mary G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Dennis Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Ricardo Pierce",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carol C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Henry",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lisa R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Olivia Moss",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Brooke G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Susan James",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jennifer F.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Susan Duncan",
        "service_type": "Garage Door Installation",
        "tech_name": "Kimberly G.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Jack Benton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrea G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Seth Anderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrew E.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "David Warner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Devin W.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Lisa Jackson",
        "service_type": "Garage Door Services",
        "tech_name": "Edward H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Charles Stone",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacob W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Scott Thompson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Gabrielle Alexander",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Brenda Guerra",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gabriel A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Brandi Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Eric J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-19",
        "review_rate": 5.0,
        "customer_name": "Diana Wyatt",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James P.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Katherine Rodriguez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Amanda Ramsey",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nathan J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Katie Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dana M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Amy Richmond",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tonya C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Matthew Thompson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kristi D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Angela Arnold",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kathryn C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Francis Lawson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elaine T.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Michael Hodges",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Chelsea W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Lisa Parker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nathan B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Amanda Walker",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sean O.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Jessica Martin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Glenn E.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Thomas Diaz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Diana G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Kathy Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Antonio S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Rebecca Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stacy P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Sarah Miller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kaylee R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Sarah Nguyen",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy L.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Michael Martin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Paul Burton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bryan M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Theresa Cunningham",
        "service_type": "Garage Door Services",
        "tech_name": "Megan P.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Jonathan Ortega",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Willis",
        "service_type": "Garage Door Repair",
        "tech_name": "Alexander B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Kyle Pearson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Stephen Nunez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Charles K.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Jasmine Anderson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Karen A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Shawn Park",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alyssa S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Colleen Hunter",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tiffany M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Jason Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Mr. Joshua Kelly Jr.",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jasmine H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Elizabeth White",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Katie O.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Brian Porter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicholas W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Pedro Soto",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Thomas B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Dustin Cook",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Judy Juarez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brad N.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Ebony Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vicki K.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Christian Walker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Christina Cox",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Michelle Kerr",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Renee J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
